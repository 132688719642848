import React from 'react';
import { Box, Typography, Card, useMediaQuery, useTheme } from '@mui/material';
import { styled } from '@mui/system';

const MainContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '40px',
  paddingBottom: '200px',
  backgroundColor: 'rgba(5, 36, 51, 1)',
  '@media (max-width: 600px)': {
    paddingBottom: '100px',
    paddingTop: '50px'
  },
});

const PricingHeading = styled(Typography)({
  marginBottom: '32px',
  color: '#fff',
});

const CardContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'stretch',
  flexDirection: 'row',
  gap: '32px',
  '@media (max-width: 600px)': {
    flexDirection: 'column',
    gap: '16px',
  },
});

const CardStyled = styled(Card)(({ variant }) => ({
  padding: '24px',
  textAlign: 'center',
  borderRadius: '20px',
  border: '2px solid transparent',
  marginLeft: variant === 'right'? '50px':'auto',
  marginRight: variant === 'left'? '150px':'auto',
  position: variant === 'center'? 'absolute': 'none',

  borderTop: variant === 'top' ? '2px solid rgba(255, 190, 76, 0.5)' : 'none',
  borderBottom: variant === 'bottom' ? '2px solid rgba(255, 190, 76, 0.5)' : 'none',
  borderBottom: variant === 'left'|| 'right' ? '2px solid rgba(255, 190, 76, 0.5)' : 'none',
  borderLeft: variant === 'left' ? '2px solid rgba(255, 190, 76, 0.5)' : 'none',
  borderTop: variant === 'left' || 'right' ? '2px solid rgba(255, 190, 76, 0.5)' : 'none',
  borderRight: variant === 'right' ? '2px solid rgba(255, 190, 76, 0.5)' : 'none',
  background: variant === 'center' ? 'linear-gradient(45deg, rgba(5, 36, 51, 2), rgba(255, 190, 76, 0.2), rgba(5, 36, 51, 1))' 
  : 'transparent',
  color: '#fff',
  '@media (max-width: 600px)': {
    position: variant === 'center'? 'relative': 'initial',
    borderRadius: '20px',
    marginBottom: '16px',
    
    maxWidth: '100%',
  },
}));

const Pricing = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <MainContainer style={{paddingTop: isMobile? 5:70}}>
      <PricingHeading variant="h4" style={{fontFamily: 'Raleway', fontSize: '48px', fontWeight: 600 }}>Pricing</PricingHeading>
      <CardContainer style={{padding: '20px'}}>
        {isMobile && (
          <CardStyled variant="top">
            <Typography variant="h6">FREEMIUM</Typography>
            <Typography>2 Hour Speaking time <b>/Month</b><br />10 Hours Listening time <b>/Month</b> </Typography>
          </CardStyled>
        )}
        <CardStyled variant="center" style={{marginLeft: !isMobile? 40 : 'auto' }}>
          <Typography variant="h6">PREMIUM</Typography>
          <Typography>Unlimited Speaking<br />Unlimited Listening<br />No Ads<br />Verified badge<br />Camera/Screen Sharing<br />And much more...</Typography>
          <Typography>for just <br /> <hr style={{
            color: 'white', fontWeight:'bolder', padding: '3px'
          }} /> <b>$2.99/Month</b></Typography>
            <Typography><br /><b>$29.99 /Year</b></Typography>
        </CardStyled>
        {isMobile && (
          <CardStyled variant="bottom">
            <Typography variant="h6">PLATINUM</Typography>
            <Typography>Reach out to us if you are<br />an Author or an Influencer</Typography>
          </CardStyled>
        )}
      </CardContainer>
      {!isMobile && (
        <CardContainer>
          <CardStyled  variant="left" >
            <Typography variant="h6">FREEMIUM</Typography>
            <Typography>2 Hour Speaking time <b>/Month</b><br />10 Hours Listening time <b>/Month</b></Typography>
          </CardStyled>
          <CardStyled  variant="right">
            <Typography variant="h6">PLATINUM</Typography>
            <Typography>Reach out to us if you are<br />an Author or an Influencer</Typography>
          </CardStyled>
        </CardContainer>
      )}
    </MainContainer>
  );
};

export default Pricing;
