import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import Header from "../components/Header";
import { getAuthors, getFamousPersonLink } from "../services/api";
import { FaQuoteLeft, FaQuoteRight, FaShare } from "react-icons/fa";
import Footer from "../components/Footer";

export default function FamousPersonDetails() {
  const [modal, setModal] = useState(false);
  const [author, setAuthor] = useState(null);
  const [shareableLink, setShareableLink] = useState("");
  const { id } = useParams();

  useEffect(() => {
    const fetchAuthor = async () => {
      let page = 1;
      let foundAuthor = null;

      try {
        while (!foundAuthor) {
          const authors = await getAuthors(page);
          foundAuthor = authors.find((author) => author._id === id);

          if (foundAuthor) {
            setAuthor(foundAuthor);
            break;
          }

          if (authors.length < 20) {
            break;
          }

          page += 1;
        }

        if (!foundAuthor) {
          console.error("Author not found");
        }
      } catch (error) {
        console.error("Failed to fetch author:", error);
      }
    };

    fetchAuthor();
  }, [id]);
  const handleShareClick = async () => {
    try {
      console.log("Sharing link...");
      const response = await getFamousPersonLink(
        author.imageUrl,
        author._id,
        author.famousPersonName,
        author.quote
      );

      console.log("API Response:", response);

      const shareableLink = response?.shortLink || response?.previewLink;

      if (shareableLink) {
        await navigator.clipboard.writeText(shareableLink);
        alert("Link copied to clipboard!");
      } else {
        console.error(
          "Failed to get shareable link: Invalid response structure",
          response
        );
      }
    } catch (error) {
      console.error("Error generating shareable link:", error);
    }
  };

  if (!author) {
    return (
      <div className="flex flex-col items-center justify-center min-h-screen bg-dark-blue text-white">
        <p className="text-lg font-semibold mb-4">
          Please wait while we fetch the data...
        </p>

        <div className="flex space-x-2">
          <div className="h-3 w-3 bg-yellow-500 rounded-full animate-bounce delay-100"></div>
          <div className="h-3 w-3 bg-yellow-500 rounded-full animate-bounce delay-200"></div>
          <div className="h-3 w-3 bg-yellow-500 rounded-full animate-bounce delay-300"></div>
        </div>
      </div>
    );
  }

  return (
    <>
      <Header setModal={setModal} modal={modal} />
      <div className="relative min-h-screen pt-11 scroll-smooth bg-dark-blue">
        <div className="max-w-7xl mx-auto px-4 py-6">
          {/* Author Quote */}
          <div className="text-center">
            <FaQuoteLeft className="inline-block text-yellow-500 mr-2" />
            <span className="text-white italic">{author.quote}</span>
            <FaQuoteRight className="inline-block text-yellow-500 ml-2" />
          </div>

          {/* Author Details */}
          <div className="text-center mt-10">
            <img
              src={author.imageUrl}
              alt={author.famousPersonName}
              className="inline-block rounded-full h-32 w-32 object-cover border-4 border-white"
            />

            <div className="flex items-center justify-center mt-4">
              <h1 className="text-2xl text-white">{author.famousPersonName}</h1>
              <FaShare
                onClick={handleShareClick}
                color="white"
                size={23}
                className="ml-3 cursor-pointer"
              />
            </div>

            <p className="text-sm text-gray-400">{author.caption}</p>
          </div>

          <br />
          <hr
            className="border-t-4 border-yellow-500 mx-auto px-4"
            style={{ fontWeight: "bold", width: "80%" }}
          />
          <br />

          {/* Author's Books */}
          <div className="flex justify-center">
            <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6 gap-3">
              {author.books.map((book) => (
                <Link
                  key={book._id}
                  to={`/book/${book._id}`}
                  className="p-2 w-28 bg-[rgba(253,254,227,0.2)] rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300"
                >
                  <img
                    src={book.imageUrl}
                    alt={book.title}
                    className="h-32 w-24 object-cover rounded-lg"
                  />
                  <div className="mt-2 text-center">
                    <p className="text-white text-sm truncate">{book.title}</p>
                  </div>
                </Link>
              ))}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
