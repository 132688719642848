import * as React from "react";
import { useParams } from "react-router-dom";
import {
  getAllPodcastsForBook,
  getBook,
  getBookclubsForBook,
  getDynamicLink,
  getRelatedBooks,
  getUserProfile,
} from "../services/api";
import ReactGA from "react-ga4";
import Footer from "../components/Footer";
import BookCard from "../components/BookCard";
import ClubCard from "../components/ClubCard";
import Header from "../components/Header";
import ReviewCard from "../components/ReviewCard";
import TalkCard from "../components/TalkCard";
import { Downloader } from "../components/Downloader";
import dateTimeFormat from "../utils/date-time-format";

export default function BookInfo() {
  let { bookId, userName } = useParams();
  const [book, setBook] = React.useState(null);
  const [bookClubs, setBookclubs] = React.useState([]);
  const [relatedBooks, setRelatedBooks] = React.useState([]);
  const [reviews, setReviews] = React.useState(null);
  const [podcasts, setPodcasts] = React.useState(null);
  const [amazonId, setAmazonId] = React.useState(null);
  const [modal, setModal] = React.useState(false);
  //   const [bookclubs, setBookclubs] = React.useState(null);
  React.useEffect(() => {
    ReactGA.initialize("G-WV2BKR9G68");
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname + window.location.search,
    });
    if (userName) {
      getUserProfile(userName).then((userData) => {
        // console.log('USERDATA:',userData);
        setAmazonId(userData.userInfo.amazonAffiliateId)
      })
    }

    // getRecentbooks().then((bookData) => {
    // console.log(bookData);
    //   setbooks(bookData);
    // });
    getBook(bookId ?? "").then((bookData) => {
      if (bookData) {
        const lst = bookData?.comments ?? [];
        setReviews(
          lst.map(
            ({ _id,
              user: {
                userInfo: { userName, userProfileUrl },
              },
              message,
              rating,
            }: any) => ({ _id, message, rating, userName, imageUrl: userProfileUrl })
          )
        );
        setBook(bookData.book[0]);
        getAllPodcastsForBook(bookData.book[0]._id).then((podcastData) => {
          setPodcasts(
            podcastData.map(
              ({
                book: { title, author, imageUrl, _id: bookId, bookCoverColor },
                participants,
                speakers, coAdmins,
                owner,
                bookSessions,
                _id,
              }: any) => {
                const coverColor = bookCoverColor && bookCoverColor.split(',');
                return ({
                  owner,
                  _id,
                  book: {
                    title,
                    author,
                    imageUrl, _id: bookId
                  },
                  speakers: speakers.length + coAdmins.length + 1,
                  listeners: participants.length,
                  bookSessions,
                  background: coverColor ? `rgb(${coverColor[0]},${coverColor[1]},${coverColor[2]})` : 'rgb(255 237 213)',
                  dateTime: dateTimeFormat(bookSessions[0].startDate),
                  action: "Join Now",
                })
              }
            )
          );
        });
        getRelatedBooks(bookData.book[0]._id).then((relatedBooks) => {
          setRelatedBooks(relatedBooks.filter(({ _id }) => _id != bookId));
        });
        getBookclubsForBook(bookData.book[0]._id).then((bookclubs) => {
          setBookclubs(
            bookclubs.map(
              ({
                bookClubInfo: {
                  _id,
                  bookClubName,
                  owner: {
                    userInfo: { firstName, lastName, userName, userProfileUrl },
                  },
                  bookClubCoverColor,
                  description,
                  imageUrl,
                  members,
                  coAdmins,
                },
              }) => {
                const coverColor = bookClubCoverColor && bookClubCoverColor.split(',');
                return ({
                  _id,
                  bookClubName,
                  host: firstName + " " + lastName,
                  imageUrl,
                  members: 1 + members.length + coAdmins.length,
                  description,
                  action: "Join Club",
                  background: coverColor ? `rgb(${coverColor[0]},${coverColor[1]},${coverColor[2]})` : 'rgb(255 237 213)',
                  memberProfiles: [
                    { firstName, lastName, userName, userProfileUrl },
                    ...coAdmins.map(
                      ({ userInfo }: any) => userInfo
                    ),
                    ...members.map(
                      ({ userInfo }: any) => userInfo
                    ),
                  ],
                  location: "Vancouver",
                })
              }
            )
          );
        });
      }
    });
  }, []);
  return (
    <div className="relative min-h-screen">
      <Header setModal={setModal} modal={modal} />
      <Downloader setModal={setModal} modal={modal} />
      {!book ? (
        <div>loading...</div>
      ) : (
        <div className="flex flex-col pt-12 pb-52 items-center">
          <div className="flex flex-col items-center justify-items-center gap-6 sm:gap-8 px-4 py-6 sm:px-12 sm:py-8 max-w-7xl font-body text-body-1 text-white">
            <div className="flex flex-col items-center gap-4 sm:gap-6 pt-0 sm:pt-0 p-5 sm:p-11">
              <div className="flex flex-row gap-4 sm:gap-8 items-center">
                <BookCard book={book} big />
                <div className="flex flex-col sm:gap-2">
                  <p className="text-body-2 sm:text-body-1 text-gray-500">
                    {book.author}
                  </p>
                  <p className="sm:font-title uppercase sm:font-bold text-body-1 sm:text-title-1">
                    {book.title}
                  </p>
                  <p className="text-small-1 sm:text-body-2 text-justify text-ellipsis line-clamp-[8] max-w-80 sm:max-w-xl overflow-auto">
                    {book.description ?? ''}
                  </p>
                  <div className="flex flex-row gap-4 sm:gap-8 pt-2 sm:pt-4">
                    <div className="flex px-4 sm:px-8 py-1 sm:py-2 rounded-full place-content-center border box-content border-solid hover:sm:bg-gradient1 hover:border-gradient1 border-gray-200 hidden">
                      <span className="icon-[material-symbols--play-circle] drop-shadow-card w-6 h-6 sm:w-8 sm:h-8"></span>
                    </div>
                    <div onClick={() => {
                      window.open(`https://amazon.se/gp/search?index=books&keywords=${book.title} ${book.author} &tag=${!amazonId || amazonId == '' ? 'moniro99-21' : amazonId}`, '_blank')
                    }} className="flex px-4 sm:px-8 py-1 sm:py-2 rounded-full place-content-center border box-content border-solid hover:sm:bg-gradient1 hover:cursor-pointer hover:border-gradient1 border-gray-200">
                      <span className="icon-[material-symbols--shopping-cart] drop-shadow-card w-6 h-6 sm:w-8 sm:h-8"></span>
                    </div>
                    <div
                      onClick={async () => {
                        try {
                          const response = await getDynamicLink(book.title, book.description, book.imageUrl, bookId);

                          if (response?.shortLink) {
                            await navigator.clipboard.writeText(response.shortLink);
                            alert("Link copied: " + response.shortLink);
                          } else {
                            alert("Failed to generate a shareable link.");
                          }
                        } catch (error) {
                          console.error("Error generating shareable link:", error);
                          alert("An error occurred while generating the shareable link.");
                        }
                      }}
                      className="flex px-4 sm:px-8 py-1 sm:py-2 rounded-full place-content-center border box-content border-solid hover:sm:bg-gradient1 hover:cursor-pointer hover:border-gradient1 border-gray-200"
                    >
                      <span className="icon-[material-symbols--share] drop-shadow-card w-6 h-6 sm:w-8 sm:h-8"></span>
                    </div>

                  </div>
                </div>
              </div>
              {reviews && reviews.length > 0 && (
                <div className="flex flex-col items-center">
                  <p className="text-body-2 sm:text-body-1 pb-4 sm:pb-6">
                    Top reviews
                  </p>
                  <div className="flex flex-row flex-wrap justify-center gap-4 sm:gap-8">
                    {reviews.map((review) => (
                      <ReviewCard key={review._id} review={review} />
                    ))}
                  </div>
                  {/* <a
                    href=""
                    className="underline mt-4 text-small-1 sm:text-body-2 font-light"
                  >
                    View more
                  </a> */}
                </div>
              )}
              {podcasts && podcasts.length > 0 && (
                <div className="flex flex-col items-center">
                  <p className="text-body-2 sm:text-body-1 pb-4 sm:pb-6">
                    Podcasts discussing this book
                  </p>
                  <div className="flex flex-row flex-wrap justify-center gap-4 sm:gap-8">
                    {podcasts.map((podcast) => (
                      <a
                        key={podcast._id} href={`${location.protocol}//${location.host}/booktalk/${podcast._id}`}
                      >
                        <TalkCard talk={podcast} book={podcast.book} />
                      </a>
                    ))}
                  </div>
                  {/* <a
                    href=""
                    className="underline mt-4 text-small-1 sm:text-body-2 font-light"
                  >
                    View more
                  </a> */}
                </div>
              )}
              {bookClubs && bookClubs.length > 0 && (
                <div className="flex flex-col items-center">
                  <p className="text-body-2 sm:text-body-1 pb-4 sm:pb-6">
                    Clubs reading this book
                  </p>
                  <div className="flex flex-row flex-wrap justify-center gap-4 sm:gap-8">
                    {bookClubs.map((bookClub) => (
                      <a
                        key={bookClub._id} href={`${location.protocol}//${location.host}/bookclub/${bookClub._id}`}
                      >
                        <ClubCard club={bookClub} />
                      </a>
                    ))}
                  </div>
                  {/* <a
                    href=""
                    className="underline mt-4 text-small-1 sm:text-body-2 font-light"
                  >
                    View more
                  </a> */}
                </div>
              )}
            </div>

            <div className="h-0.5 sm:h-1 bg-gray-400 bg-opacity-75 rounded-full w-1/4"></div>
            {relatedBooks.length > 0 && (
              <div className="flex flex-col items-center pt-5 sm:pt-8">
                <p className="text-body-2 sm:font-bold sm:text-title-2 sm:font-title uppercase pb-4 sm:pb-6">
                  Related <strong>Books</strong>
                </p>
                <div className="flex flex-row flex-wrap justify-center w-full gap-4 sm:gap-8">
                  {relatedBooks.map((relatedBook) => (
                    <a
                      key={relatedBook._id} href={`${location.protocol}//${location.host}/book/${relatedBook._id}`}
                    >
                      <BookCard book={relatedBook} />
                    </a>
                  ))}
                </div>
                {/* <a
          href=""
          className="underline mt-4 text-body-2 sm:text-body-1 font-light"
        >
          View more
        </a> */}
              </div>
            )}
          </div>
        </div>
      )}
      <Footer />
    </div>
  );
}
